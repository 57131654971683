import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

//Images
import { OurPeopleImages } from '../imageURLs';

import SEO from '../components/SEO';
import { withQuoteWizard } from '../components/withQuoteWizard';
import CenterContent from '../components/CenterContent';
import PageDescription from '../components/OurPeople/pageDescription';
import TeamMemberCard from '../components/OurPeople/teamMember';
import SectionTitle from '../components/SectionTitle';
import InnerImage from '../components/InnerImage/innerImage';
// import memberData from '../../data/our-people/memberData.json';



// function shuffleArray() {
//   const array = [ 0,1,2,3,4,5,6,7,8,9 ];
//   let i = array.length - 1;
//   for (; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     const temp = array[i];
//     array[i] = array[j];
//     array[j] = temp;
//   }
//   return array;
//   }


const styles = theme =>
  createStyles({
    cardsWrapper: {
     paddingBottom: 60,
     flexWrap: 'wrap',
    },

    introWrap: {
      maxWidth: 850,
      margin: '0 auto',
      marginTop: 45
    },

    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  });

class OurPeople extends React.Component {
  render() {
    const { children, classes } = this.props;
    // const teamMembers = shuffleArray();

    
    return (
    <React.Fragment>
        <SEO
        title="Our People - Twin View Turf - Producer of Australia’s Best Lawns"
        keywords={[
            `Turf care guides`,
            `care guides`,
            `Turf`,
            `Lawns`,
            `Best Lawns`,
            `Australia’s Best Lawns`,
            `lawn producer`,
        ]}
        />

        <InnerImage
          image={{className: classes.img, src: OurPeopleImages.hero}}
        >  </InnerImage>

        <CenterContent>

          <Grid
            container
            direction="column"
            className={classes.introWrap}
          >

            <SectionTitle
              title="Our People"
              subTitle="Bringing passion to our projects."
            ></SectionTitle>

            <PageDescription
              descText = 'We are a group of committed and passionate people who deliver quality projects. Our collaborative approach and combined expertise takes a project seamlessly from concept to completion. Our commitment to quality is embedded in our work culture as seen in our extensive project portfolio.'
            >
            </PageDescription>
          </Grid>

        </CenterContent>

        <CenterContent style={{ maxWidth: '1340px' }}  >
          <Grid
          container
          className={classes.cardsWrapper}
          >
            {/* {teamMembers.map((index) => {
            return (
              <TeamMemberCard 
                key = {memberData[index].name}
                cardImage = {memberData[index].profileImg}
                memberName= {memberData[index].name}
                memberPosition = {memberData[index].position}      
              ></TeamMemberCard>
            );

            })} */}

        <TeamMemberCard 
                cardImage = { OurPeopleImages.member1 }
                memberName="Lawrence Stephenson"
                memberPosition = "General Manager"
            ></TeamMemberCard>

            <TeamMemberCard 
                cardImage = { OurPeopleImages.member2 }
                memberName="John Shaw"
                memberPosition = "Project & Renovation Manager"
            ></TeamMemberCard>


            <TeamMemberCard 
                cardImage = { OurPeopleImages.member3 }
                memberName="Trent Hobson"
                memberPosition = "Project Manager"
            ></TeamMemberCard>

            <TeamMemberCard 
                cardImage = { OurPeopleImages.member4 }
                memberName="Sabra Matthews"
                memberPosition = "Construction Admin Manager"
            ></TeamMemberCard>

            <TeamMemberCard 
                cardImage = { OurPeopleImages.member5 }
                memberName="Jy Cross"
                memberPosition = "Construction Site Foreman"
            ></TeamMemberCard>

            <TeamMemberCard 
                cardImage = { OurPeopleImages.member6 }
                memberName="Scott Heather"
                memberPosition = "Project Leading Hand"
            ></TeamMemberCard>

            <TeamMemberCard 
                cardImage = { OurPeopleImages.member7 }
                memberName="Katrina Elsden"
                memberPosition = "Distribution Manager"
            ></TeamMemberCard>

            <TeamMemberCard 
                cardImage = { OurPeopleImages.member8 }
                memberName="Paul Weldon"
                memberPosition = "Agricultural Manager"
            ></TeamMemberCard>

            <TeamMemberCard 
                cardImage = { OurPeopleImages.member9 }
                memberName="Ryan Pancari"
                memberPosition = "Workshop Manager"
            ></TeamMemberCard>

            <TeamMemberCard 
                cardImage = { OurPeopleImages.member10 }
                memberName="Sam Hall"
                memberPosition = "Marketing Specialist"
            ></TeamMemberCard>

          </Grid>
        </CenterContent>
    
    {children}
  </React.Fragment>
    );
  }
}

export default withQuoteWizard(
  withStyles(styles, { withTheme: true })(OurPeople)
);
