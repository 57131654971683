import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import CardImage from '../CardImage';

const styles = theme =>
  createStyles({
    cardItem: {
        width: '100%',
        position: 'relative',
        marginBottom: 30,
        // [theme.breakpoints.up(414)]: {
        //     flex: '0 0 50%',
        //     maxWidth: '50%',
        //     padding: 5,
        //     marginBottom: 5,
        // },
        [theme.breakpoints.up(350)]: {
            flex: '0 0 50%',
            maxWidth: '50%',
            padding: 5,
            marginBottom: 5,
        },
        [theme.breakpoints.up(767)]: {
            padding: 10,
            marginBottom: 0
        },
        [theme.breakpoints.up(960)]: {
            flex: '0 0 33.33%',
            maxWidth: '33.33%',
        },
        [theme.breakpoints.up(1280)]: {
            flex: '0 0 25%',
            maxWidth: '25%',
        },
    },

    cardContent: {
        backgroundColor: theme.palette.grey.main,
        height: '100%', //Egaul card height
    },

    cardImageWrap: {
        position: 'relative',
        zIndex: 1,
        top: '0',
        left: '0',
    },
    
    cardImage: {
        width: '100%',
        height: '300px',

        [theme.breakpoints.up(350)]: {
            height: '180px',
        },
        [theme.breakpoints.up(500)]: {
            height: '230px',
        },

        [theme.breakpoints.up(600)]: {
            height: '300px',
        },

        [theme.breakpoints.up(767)]: {
            height: '310px',
        }
    },
    cardTextWrap: {
        padding: '10px 15px',
        [theme.breakpoints.up(767)]: {
            padding: '15px 20px',
        }
    },
    cardText: {
        fontSize: 13,
        fontWeight: 300,
        letterSpacing: '0.3px',
        [theme.breakpoints.up(500)]: {
            fontSize: 18,
            lineHeight: '22px',    
        },
        [theme.breakpoints.up(767)]: {
            fontSize: 20,
            lineHeight: '22px',    
        }
    },
    strongText: {
        fontWeight: 600
    }
  });

  const TeamMemberCard = ({classes,cardImage,memberName,memberPosition}) => (

        <Grid
            container  
            direction="column"
            className= {classes.cardItem}
        >

            <Grid
                className= {classes.cardContent}
            >

            {cardImage && (
                <Grid item className={classes.cardImageWrap}>
                    <CardImage relativePath={cardImage} className={classes.cardImage}></CardImage>
                </Grid>
            )}

            <Grid
                container
                direction= "column"
                className={classes.cardTextWrap}
            > 
                <Typography
                    color= "primary"
                    variant = "body2"
                    className = { classes.cardText }
                >
                   <strong className={ classes.strongText}> {memberName}</strong> <br/>
                    {memberPosition}
                </Typography>

            </Grid>

            </Grid>

        </Grid>

  )
  export default withStyles(styles, { withTheme: true })(TeamMemberCard);