import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';


const styles = theme =>
  createStyles({
    descWrap: {
      paddingTop: 25,
      paddingBottom: 5,
      [theme.breakpoints.up(960)]: {
        paddingBottom: 60
      },
    },

    description: {
      backgroundColor: theme.palette.grey.main,
    },
    descText: {
      fontSize: 20,
      padding: '40px',
      lineHeight: '23px',
      fontWeight: '300',
      alignItems: 'center',
      textAlign: 'center',
      [theme.breakpoints.up(767)]: {
        alignItems: 'flex-start',
        textAlign: 'left',
        lineHeight: '36px',
        fontSize: 24,
      },
    }

  });


const PageDescription = ({classes,descText}) => (
  <Grid
    container
    item
    direction="column"
    className={classes.descWrap}
  >

      <div className={classes.description}>
        <Typography
          varinat="body1"
          color="primary"
          className = {classes.descText} 
        >
          {descText}
        </Typography>

      </div>

  </Grid>

)

export default withStyles(styles, { withTheme: true })(PageDescription);